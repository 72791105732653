<template>
  <div>

    <b-card no-body>
      <div class="m-2">
        <b-row>

          <b-col cols="6" md="4">
            <label class="col-form-label">Select Subject</label>

            <v-select class="w-100" v-model="SelectedSubject" placeholder="Select a subject" :clearable="true"
              :reduce="(subjects) => subjects._id" :options="subjects" label="subjectName" @input="selectSubject()">
              <template v-slot:option="option">
                {{ option.subjectName }}
                <span v-if="option.class">
                  ({{ option.class.className }})
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col cols="6" md="4">
            <label class="col-form-label">Select Topic</label>

            <v-select class="w-100" v-model="SelectedTopic" placeholder="Select a topic" :clearable="true"
              :reduce="(topics) => topics._id" :options="topics" label="categoryName" @input="selectTopic()">
              <template v-slot:option="option">
                {{ option.categoryName }}
                <span v-if="option.class">
                  ({{ option.class.className }})
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col cols="6" md="4">
            <div class="d-flex w-100 gap-2" style="justify-content: space-between;">

              <div class="pagination-col">
                <user-dropdown :options="perPageOptions" :selected-option="perPage" labelValue="Per Page"
                  @getUserDropDown="perPageDropdown" placeholder="All" />
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <b-col cols class="d-flex justify-content-end align-items-end mb-1">
        <div class="">{{ from }} - {{ to }} of {{ totalItems }}</div>
      </b-col>

      <b-overlay rounded="sm">
        <template v-if="!isloading">
          <template v-if="totalItems !== 0">

            <b-table :items="finalLogsData" :fields="fields" responsive class="mb-0" id="custom-table">

              <template #cell(user)="data">
                <span>
                  {{ data.item.user || "-" }} <br />
                  {{ data.item.user_name || "-" }}
                </span>
              </template>

              <template #cell(date)="data">
                <span>
                  {{ data.item.date || "-" }} <br />
                  {{ data.item.time || "-" }}
                </span>
              </template>

              <template #cell(questionTitle)="data">
                <div class="d-flex align-items-center" >
                  {{ data.item.questionTitle || "-" }}
                </div>
              </template>

              <template #cell(action)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.action || "-" }}
                </div>
              </template>

              <!-- style=" width: 320px; max-width: 320px;" -->

              <template #cell(changes)="data">
                <div class="d-flex align-items-center" >
                  {{ data.item.changes || "-" }}
                </div>
              </template>

              <template #cell(path)="data">
                <div class="d-flex align-items-center">
                  <b-link :to="{
                    path: getQuestionPath(data.item),
                    query: displayPath(data.item) ? {
                      categoryId: data.item.topicData.id,
                      theme: true
                    } : {}
                  }">
                    {{ displayPath(data.item) ? `${displayPath(data.item)} / Questions` : '-' }}
                  </b-link>
                </div>
              </template>

            </b-table>

          </template>
          <template v-else>
            <div class="text-center m-2">No records found</div>
          </template>
        </template>
        <div v-else class="p-4 text-center">
          <b-spinner v-if="isloading" label="Loading..." medium></b-spinner>
        </div>
        <div style="display: flex; flex-direction: row-reverse">
          <div>

            <b-pagination v-model="currentPage" :total-rows="totalItems" :limit=2 :per-page="perPage"
              @change="getQuestionLogs" class="m-t-10">
            </b-pagination>

          </div>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BOverlay,
  BFormGroup,
  BTableSimple,
  BThead,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BSpinner,

} from "bootstrap-vue";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import FeedBackStatusActive from "./app/list/users-list/FeedBackStatusActive.vue";
import UserDropdown from "../views/components/UserDropdown.vue";
import ScreenshotModal from "../views/components/ScreenshotModal.vue";
import FeedbackModal from "../views/components/FeedbackModal.vue";
import DateRangeComponent from "../views/components/DateRangeComponent.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TokenService from "../TokenService";
import * as Helpers from "../Helpers";
import vSelect from "vue-select";

export default {
  components: {
    FeedBackStatusActive,
    UserDropdown,
    ScreenshotModal,
    FeedbackModal,
    DateRangeComponent,
    BBreadcrumb,
    BCard,
    BOverlay,
    BFormGroup,
    BAvatar,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    ToastificationContent,
    DateRangePicker,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      totalItems: 0,
      from: 0,
      to: 0,
      isloading: true,
      users: [],
      levelId: "",
      questionId: "",
      categoryId: "",
      subjectId: "",

      fields: [
        { key: "user", label: "USER" },
        { key: "date", label: "DATE" },
        { key: "questionTitle", label: "QUESTIN TITLE" },
        { key: "action", label: "ACTION" },
        { key: "changes", label: "CHANGES" },
        { key: "path", label: "PATH" },
      ],
      selectedStatus: "All",

      assignUserId: {
        id: "",
      },
      feedbackData: [],
      logsData: [],
      finalLogsData: [],

      SelectedSubject: 0,
      subjects: [],
      SelectedTopic: 0,
      topics: [],
      TokenService,
      Helpers,
      questionLogs: {
        data: [
          {
            user: 'Rizwan Iqbal',
            email: 'rizwan.iqbal@gmail.com',
            date: '21/02/2025',
            time: '12:00 PM',
            questionTitle: 'This is a question title',
            action: 'Created',
            changes: 'Here is the detail of changes'
          }
        ]
      }
    };
  },
  computed: {

  },
  created() {
    if (!TokenService.getPermissions("view_question_logs")) {
      this.$router.push("/error-404");
    }

    this.getQuestionLogs();
    this.getQuestionDetails()

  },
  watch: {

  },
  methods: {

    getQuestionPath(item) {

      if (!item || !item.levelData || !item.questionId) {
        return '#';  
      }
      return `/categorylevels/question/${item.levelData.id}/${item.questionId}`;
    },

    displayPath(item) {
      let parts = [];
      if (item.classData?.name) parts.push(item.classData?.name);
      if (item.subjectData?.name) parts.push(item.subjectData?.name);
      if (item.topicData?.name) parts.push(item.topicData?.name);
      if (item.levelData?.name) parts.push(item.levelData?.name);
      return parts.join(' / ');
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },

    formatTime(dateString) {
      const date = new Date(dateString);

      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    selectSubject(data) {
      if (data) {
        this.SelectedSubject = data;
      }
      this.SelectedTopic = 0;
      this.getQuestionLogs();
      this.getQuestionDetails();
      this.currentPage = 1;
    },
    selectTopic(data) {
      if (data) {
        this.SelectedTopic = data._id;
      }
      this.getQuestionLogs();
      this.currentPage = 1;
    },

    perPageDropdown(data) {

      if (data) {
        this.perPage = data
      } else {
        this.perPage = 10
      }

      this.changePerPage();
      this.currentPage = 1;
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },
    changePerPage() {
      this.getQuestionLogs();
    },

    async getQuestionDetails() {
      try {
        const params = {};

        if (this.SelectedSubject) {
          params.subjectId = this.SelectedSubject;
        }

        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/question-logs/question-details`,
          {
            params: params,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        );

        const { data } = response;

        this.subjects = data.data.subjects || [];
        this.topics = data.data.categories || [];
        // this.notificationMessage = data.message || "Question details fetched successfully.";
        // this.showToasted("success");

      } catch (error) {
        console.error("Error fetching question details in question logs:", error.response);
        // this.notificationMessage =
        //     error.response?.data?.message || "An error occurred. Please try again later.";
        // console.error("Error fetching question details:", error.response);

        // this.showToasted("danger");

        this.subjects = [];
        this.categories = [];

      } finally {
        // this.isloading = false; // Hide loading state
      }
    },


    async getQuestionLogs(page = 1) {
      this.isloading = true;
      this.currentPage = page;

      try {
        const params = {
          page: page,
          perPage: this.perPage,
        };

        if (this.SelectedSubject) {
          params.subjectId = this.SelectedSubject;
        }
        if (this.SelectedTopic) {
          params.categoryId = this.SelectedTopic;
        }

        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/questions/question-logs`,
          {
            params: params,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        );

        this.logsData = response.data.logs || [];
        this.totalItems = response.data.pagination.total || 0;

        this.from = (this.currentPage - 1) * this.perPage + 1;
        this.to = Math.min(this.currentPage * this.perPage, this.totalItems);

        if (this.logsData.length > 0) {
          this.finalLogsData = this.processLogsData(this.logsData);

          // console.log('updatedCategories', JSON.stringify(this.finalLogsData, null, 10))


        } else {
          this.finalLogsData = [];
        }

        this.notificationMessage = response.data.message || "Logs fetched successfully.";
        this.showToasted("success");
      } catch (error) {
        this.notificationMessage =
          error.response?.data?.message || "An error occurred. Please try again later.";
        console.error("Error fetching logs:", error.response);

        this.showToasted("danger");

        this.logsData = [];
        this.processedLogsData = [];
        this.totalItems = 0;
      } finally {
        this.isloading = false;
      }
    },

    processLogsData(logsData) {
      try {
        return logsData.map((log) => {

          const oldData = log.old_data ? JSON.parse(log.old_data) : null;
          const newData = log.new_data ? JSON.parse(log.new_data) : null;

          return {
            user: log.user_email || "-",
            user_name: log.user_name || "-",
            date: log.created_at ? this.formatDate(log.created_at) : "-",
            time: log.created_at ? this.formatTime(log.created_at) : "-",
           // questionTitle: log.questionTitle || "-",
            questionTitle: oldData?.title || newData?.title || "-",
            action: log.action || "-",
            changes: log.description || "-",
            classData: log.class || null,
            subjectData: log.subject || null,
            topicData: log.category || null,
            levelData: log.level || null,
            questionId: log.question_id || null,
          };
        });
      } catch (error) {
        console.error("Error processing logs data:", error);
        return [];
      }
    }
  },
};
</script>

<style lang="scss">
img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}

.table td {
  padding: 1rem !important;
}

.table th {
  padding: 1rem !important;
}

.per-page-selector {
  width: 90px;
}

.padding_table {
  padding: 0.72rem 1.4rem !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.dropdown button {
  padding: 0 !important;
}

.dropdown-menu {
  min-width: 8rem;
  margin-top: -17px !important;
}

.dropdown a.dropdown-item {
  padding: 0px 8px;
}
</style>
<style>
.vs__open-indicator {
  fill: #9d9d9d;
}
</style>
